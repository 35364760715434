export const AUTH_MS_URL = process.env.REACT_APP_AUTH_MS_URL;
export const COOKIES_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN;
export const ACCOUNTS_URL = process.env.REACT_APP_ACCOUNTS_URL;
export const RIDE_MS_URL = process.env.REACT_APP_RIDE_URL;
export const COOKIES_TOKEN_NAME = 'token';
export const COOKIES_USER_NAME = 'user';
export const COOKIES_USR = 'usr';
export const DB_PREF = 'oya_';
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const ACCOUNTS_MS_URL = process.env.REACT_APP_ACCOUNTS_MS_URL;
export const BUS_RIDE_URL = process.env.REACT_APP_BUS_RIDE_URL;

export const PaymentStatusOption = [
  { value: 'PENDING', label: 'PENDING' },
  { value: 'PAID', label: 'PAID' },
  { value: 'FAILED', label: 'FAILED' },
  { value: 'CANCELLED', label: 'CANCELLED' },
];

export const PaymentMethodOption = [
  { value: 'MOBILE_MONEY', label: 'MOMO' },
  { value: 'CASH', label: 'Cash' },
];

export const LoadingPointBookingStatuses = [
  {
    value: 'BOOKED',
    label: 'Booked',
  },
  {
    value: 'PAID',
    label: 'Paid',
  },
  {
    value: 'SCHEDULED',
    label: 'Scheduled',
  },
  {
    value: 'PAYMENT_FAILED',
    label: 'Payment failed',
  },
];
