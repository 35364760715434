import { useState, useEffect } from 'react';
import { Input, Form, Modal, notification, Spin } from 'antd';
import { Button, Typography } from '@material-tailwind/react';
import CustomButton from '../CustomButton';
import PhoneInput, { Value } from 'react-phone-number-input';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { GOOGLE_KEY } from '../../utils/constants';
import { base_api_request } from '../../utils/api';
import { getCompanies, getRegions } from '../../utils/helpers/commonApiCalls';
import { Company, NetworkProvider, Option, Region } from '../../types';
import { RouteConstants } from '../../utils/helpers/RouteConstants';

const CreateBranch = ({
  handleClosed,
  isOpen,
  handleReFetch,
}: {
  handleClosed: () => void;
  handleReFetch: () => void;
  isOpen: boolean;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState<Value | undefined>('');
  const [momo, setMomoNumber] = useState<Value | undefined>('');
  const [location, setLocation] = useState('');
  const [lat, setLatitude] = useState<any>('');
  const [lng, setLongitude] = useState<any>('');
  const [regions, setRegions] = useState<Option[]>([]);
  const [companies, setCompanies] = useState<Option[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number>(0);
  const [selectedRegion, setSelectedRegion] = useState<number>(0);
  const [theNetwork, setTheNetwork] = useState<any>('');
  const [theEmail, setTheEmail] = useState('');

  const [networks, setNetworks] = useState<Option[]>([]);
  const [fetchingProviders, setFetchingProviders] = useState(false);

  const getNetworkProviders = async () => {
    try {
      setFetchingProviders(true);
      const { data } = await base_api_request.get(
        RouteConstants.NETWORK_PROVIDER
      );
      setNetworks(
        data?.payload?.items?.map((item: NetworkProvider, index: number) => ({
          label: item?.name,
          value: item?.code,
        }))
      );
    } catch (e) {
      console.log(e);
    } finally {
      setFetchingProviders(false);
    }
  };

  const handlePlaceSelect = (place: any) => {
    setLocation(place?.label);
    geocodeByAddress(place?.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        // console.log('Successfully got latitude and longitude', { lat, lng });
        setLatitude(lat.toString());
        setLongitude(lng.toString());
      });
  };

  const loadRegionOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    setTimeout(async () => {
      let filterData = [
        {
          f: 'name',
          o: 'contains',
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await base_api_request.get(
          `${RouteConstants.ALL_REGIONS}?filters=${JSON.stringify(filterData)}`
        );

        const searchOptions = res.data?.payload?.items?.map((item: Region) => ({
          label: item?.name,
          value: item?.id,
        }));

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    }, 1000);
  };

  const loadCompanyOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    setTimeout(async () => {
      let filterData = [
        {
          f: 'name',
          o: 'contains',
          p: [`${inputValue}`],
        },
      ];

      try {
        const res = await base_api_request.get(
          `${RouteConstants.ALL_COMPANIES}?filters=${JSON.stringify(
            filterData
          )}`
        );

        const searchOptions = res.data?.payload?.items?.map(
          (item: Company) => ({
            label: item?.name,
            value: item?.id,
          })
        );

        callback(searchOptions);

        //console.log(uniqueData);
      } catch (e) {
        console.log(e);
        //setLoadingCompanies(false);
      }
    }, 1000);
  };

  const submit = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      await base_api_request.post(RouteConstants.ADD_BRANCH, {
        ...values,
        network: theNetwork,
        bus_company_id: selectedCompany,
        region_id: selectedRegion,
        phone,
        momo_number: momo,
        email: theEmail,
        location,
        longitude: lng,
        latitude: lat,
      });
      setLoading(false);
      notification.success({
        message: 'Branch created successfully',
      });
      handleClosed();
      handleReFetch();
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message,
      });
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getRegions()
      .then((res) => {
        setRegions(
          res.payload?.items?.map((items: Region) => ({
            value: items?.id,
            label: items?.name,
          }))
        );
      })
      .catch((e) => console.log(e));

    getCompanies()
      .then((res) => {
        setCompanies(
          res.payload?.items?.map((items: Company) => ({
            value: items?.id,
            label: items?.name,
          }))
        );
      })
      .catch((e) => console.log(e));

    getNetworkProviders();

    // eslint-disable-next-line
  }, []);

  return (
    <Modal open={isOpen} onCancel={handleClosed} footer={null}>
      <Spin spinning={loading}>
        <div>
          <Typography variant='h5'>Create a new Branch</Typography>
          <Form
            form={form}
            className='mt-5'
            layout='vertical'
            autoComplete='none'
          >
            <div className='sm:flex gap-2 items-center'>
              <Form.Item
                className='w-1/2'
                label='Bus Company'
                name='bus_company_id'
                required
              >
                <AsyncSelect
                  loadOptions={loadCompanyOptions}
                  onChange={(e: any) => {
                    setSelectedCompany(e?.value);
                  }}
                  isSearchable
                  isClearable
                  defaultOptions={companies}
                />
              </Form.Item>
              <Form.Item
                required
                className='w-1/2'
                label='Region'
                name='region_id'
              >
                <AsyncSelect
                  loadOptions={loadRegionOptions}
                  onChange={(e: any) => {
                    setSelectedRegion(e?.value);
                  }}
                  isSearchable
                  isClearable
                  defaultOptions={regions}
                />
              </Form.Item>
            </div>
            <div className='sm:flex gap-2 items-center'>
              <Form.Item
                required
                className='w-1/2'
                label='Branch Name'
                name='name'
              >
                <Input size='large' placeholder='name' />
              </Form.Item>
              <Form.Item
                required
                className='w-1/2'
                label='Branch Alias'
                name='alias'
              >
                <Input size='large' placeholder='alias' />
              </Form.Item>
            </div>
            <div className='flex gap-2 items-center '>
              <Form.Item
                required
                className='w-1/2'
                name='code'
                label='Specify the Branch code'
              >
                <Input size='large' placeholder='code' />
              </Form.Item>
              <Form.Item
                required
                className='w-1/2'
                name='phone'
                label='Branch Phone'
              >
                <PhoneInput
                  name='phone'
                  className='phone w-full pl-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent'
                  defaultCountry='GH'
                  onChange={(e) => {
                    setPhone(e);
                  }}
                />
              </Form.Item>
            </div>
            <div className='sm:flex gap-2 items-center'>
              <Form.Item className='w-1/2' name='network' label='MOMO Provider'>
                <Select
                  isLoading={fetchingProviders}
                  isClearable
                  options={networks}
                  onChange={(val) => setTheNetwork(val?.value)}
                />
              </Form.Item>
              <Form.Item
                className='sm:w-1/2 w-full'
                name='momo_number'
                label='Branch Momo'
              >
                <PhoneInput
                  name='phone'
                  className='phone w-full pl-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent'
                  defaultCountry='GH'
                  onChange={(e) => {
                    setMomoNumber(e);
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item className='w-full' label='Branch Email'>
              <Input
                size='large'
                value={theEmail}
                onChange={(e) => setTheEmail(e.target.value)}
                placeholder='email@domain.com'
              />
            </Form.Item>
            <Form.Item
              className='w-full'
              name='location'
              required
              label='Branch Location'
            >
              <GooglePlacesAutocomplete
                apiKey={GOOGLE_KEY}
                selectProps={{
                  onChange: handlePlaceSelect,
                }}
              />
            </Form.Item>
          </Form>
          <div className='flex w-full justify-end items-end mt-10'>
            <div className='flex items-center gap-3'>
              <CustomButton
                data-testid='submitNewBranch'
                disabled={loading}
                onClick={submit}
              >
                Submit
              </CustomButton>
              <Button
                data-testid='closeNewBranch'
                disabled={loading}
                variant='outlined'
                size='sm'
                onClick={handleClosed}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default CreateBranch;
