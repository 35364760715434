import { useState, useEffect, useMemo } from "react";
import PageLayout from "../components/layouts/PageLayout";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { AddOutlined, OpenWithOutlined } from "@mui/icons-material";
import { Dropdown, Space, Spin, notification } from "antd";
import { ArrowDropDownOutlined, EditOutlined } from "@mui/icons-material";
import { base_api_request } from "../utils/api";
import CustomButton from "../components/CustomButton";
import { Box } from "@mui/material";
// import { redirect } from '../utils/functions';

import { Company } from "../types";
import CreateCompany from "../components/Companies/CreateCompany";
import type { MenuProps } from "antd";
import EditCompany from "../components/Companies/EditCompany";
import { RouteConstants } from "../utils/helpers/RouteConstants";
import { redirect } from "../utils/functions";
import { BUS_RIDE_URL } from "../utils/constants";

function Companies() {
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | any>();
  const [editCompanyModal, showEditCompanyModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const openTipsPage = () => {
    redirect(`${BUS_RIDE_URL}/companies/${selectedCompany?.id}/trips`);
  }

  const [meta, setMeta] = useState({
    offset: 0,
    page: 1,
    limit: 12,
    total_pages: null,
    total: 0,
  });

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <button
          data-testid="editCompany"
          onClick={() => {
            showEditCompanyModal(true);
          }}
        >
          Edit Company
        </button>
      ),
      icon: (
        <EditOutlined
          sx={{
            color: "#7c0000",
          }}
        />
      ),
    },
    {
      key: "2",
      label: (
        <button
          data-testid="viewTrips"
          onClick={() => {
            openTipsPage();
          }}
        >
          View Trips (Public)
        </button>
      ),
      icon: (
        <OpenWithOutlined
          sx={{
            color: "#7c0000",
          }}
        />
      ),
    },
  ];

  const columns = useMemo<MRT_ColumnDef<Company>[]>(
    () => [
      // {
      //   accessorFn: (row) => `${row?.logo}`,
      //   header: '',
      //   Cell: ({ cell }) => (
      //     <Box>
      //       <Avatar
      //         style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}
      //         alt='company logo'
      //       >
      //         C
      //       </Avatar>
      //     </Box>
      //   ),
      //   size: 80,
      // },
      {
        accessorFn: (row) => `${row?.name}`,

        header: "Branch company name",
      },
      {
        accessorFn: (row) => `${row?.type?.name}`,

        header: "Operation type",
      },
      {
        accessorFn: (row) => `${row?.email}`,
        header: "Email",
        // Cell: ({ cell }) => (
        //   <Box>{moment(cell.getValue()).format("MMM DD, YYYY")}</Box>
        // ),
      },

      {
        accessorFn: (row) => `${row?.phone}`,

        header: "Contact number",
      },
      {
        id: "more",
        header: "",
        accessorFn: (row) => `${row?.id}`,
        Cell: ({ cell, row }) => (
          <Box>
            <Dropdown
              trigger={["click"]}
              menu={{
                items,
                onChange: (e) => {
                  //console.log('value being set', e);
                  setSelectedCompany(e);
                },
              }}
              placement="bottomRight"
            >
              <button
                data-testid="moreCompanies"
                onClick={(e) => {
                  //console.log('value in more', e);
                  e.preventDefault();
                }}
              >
                <Space>
                  More
                  <ArrowDropDownOutlined />
                </Space>
              </button>
            </Dropdown>
          </Box>
        ),
        size: 80,
      },
    ],

    // eslint-disable-next-line
    []
  );

  const getCompanies = async () => {
    setLoading(true);

    try {
      const res = await base_api_request.get(
        `${RouteConstants.ALL_COMPANIES}?limit=${pagination.pageSize
        }&offset=${Number(pagination.pageIndex * pagination.pageSize)}`
      );
      setCompanies(res.data?.payload?.items);
      setMeta((prevData) => ({
        ...prevData,
        total: res.data?.payload?.total,
      }));
      setLoading(false);
    } catch (e) {
      //console.log(e);
      setLoading(false);
    }
  };

  const updateCompany = async (values: any, phone: any, contact_phone: any) => {
    try {
      let formatPhone = phone?.replace(/\s+/g, "");
      let formatContactPhone = contact_phone?.replace(/\s+/g, "");

      setSubmitting(true);

      // console.log({
      //   ...values,
      //   phone: formatPhone,
      //   contact_phone: formatContactPhone,
      //   company_type_id: values?.company_type_id || selectedCompany?.type?.id,
      // });
      await base_api_request.put(
        `${RouteConstants.ALL_COMPANIES}/${selectedCompany?.id}`,
        {
          ...values,
          phone: formatPhone,
          contact_phone: formatContactPhone,
          company_type_id: values?.company_type_id || selectedCompany?.type?.id,
        }
      );
      setSubmitting(false);
      showEditCompanyModal(false);
      notification.success({
        message: "Company details updated",
      });
      getCompanies();
    } catch (e: any) {
      setSubmitting(false);
      notification.error({
        message: e?.response?.data?.message,
      });
    }
  };

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line
  }, [pagination.pageIndex, pagination.pageSize]);

  return (
    <PageLayout>
      <Spin spinning={submitting} className="z-[1000]">
        <CreateCompany
          reload={() => getCompanies()}
          isOpen={open}
          handleClosed={() => setOpen(false)}
        />
        <EditCompany
          company={selectedCompany}
          isOpen={editCompanyModal}
          handleClosed={() => showEditCompanyModal(false)}
          // token={token}
          handleSubmitting={(loading) => {
            setSubmitting(loading);
          }}
          updateCompany={updateCompany}
        />
        <div className="w-full overflow-auto mb-8 relative">
          <div className="col-span-3 mx-4 my-6 h-full">
            <div className="flex justify-between items-center mb-5">
              <h3 className="font-bold py-2 text-2xl">Companies</h3>
              <CustomButton
                data-testid="createCompany"
                onClick={() => setOpen(true)}
              >
                <div className="flex gap-1 text-white items-center">
                  <AddOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <div>Create Company</div>
                </div>
              </CustomButton>
            </div>

            <div className="grid grid-cols-1">
              <div className="w-full h-full mb-8">
                <MaterialReactTable
                  muiTablePaperProps={{
                    sx: {
                      borderRadius: "0",
                    },
                  }}
                  data={companies}
                  enableTopToolbar={false}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enableMultiRowSelection={false}
                  enableRowSelection={false}
                  manualPagination
                  enableSorting={false}
                  columns={columns}
                  rowCount={meta?.total}
                  state={{ pagination, isLoading: loading }}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  enableColumnResizing
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                      setSelectedCompany(row.original);
                    },
                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  onPaginationChange={setPagination}
                />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </PageLayout>
  );
}

export default Companies;
